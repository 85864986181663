import {
  MicrobotMemoryInfoFragment,
  MicrobotMemoryProcessingStatus,
} from '@advisor/api/generated/graphqlTypes';
import { isFileMemory, isUrlMemory } from '../components/MemoryListItem';

const useIsMemoryProcessing = (memory: MicrobotMemoryInfoFragment) => {
  if (isFileMemory(memory) || isUrlMemory(memory)) {
    return memory.status === MicrobotMemoryProcessingStatus.InProgress;
  }

  return false;
};

export default useIsMemoryProcessing;

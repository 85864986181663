import React from 'react';
import cs from 'classnames';
import { useAtom } from 'jotai';

import { Icon } from '@advisor/design/components';
import {
  ScreenShareStatus,
  VideoCallStatus,
  useCallState,
} from '../../VideoRoomAPI';
import { isParticipantListOpenAtom } from '../../atoms';
import useLocalSettings from '../../LocalSettings';
import { useScreenShare } from '../../ScreenShare';
import RecordingControls from '../RecordingControls';
import { ToolbarProps } from './types';
import OptionButton from './OptionButton';

const Toolbar = ({ onLeave }: ToolbarProps) => {
  const [isParticipantListOpen, setIsParticipantListOpen] = useAtom(
    isParticipantListOpenAtom,
  );
  const {
    isMicrophoneEnabled,
    isCameraEnabled,
    toggleMicrophone,
    toggleCamera,
  } = useLocalSettings();
  const [screenShareState, changeScreenShare] = useScreenShare();

  const callState = useCallState();
  const callLoadingFinished = callState?.status === VideoCallStatus.Joined;

  const handleScreenShare = () => {
    if (screenShareState === ScreenShareStatus.NotSharing) {
      changeScreenShare('share');
    } else if (screenShareState === ScreenShareStatus.Sharing) {
      changeScreenShare('unshare');
    }
  };

  return (
    <div className="relative flex justify-center items-stretch py-7 px-6">
      <div className="flex-1 flex justify-start">
        <button
          type="button"
          className={cs(
            'self-end bg-white rounded-lg',
            isParticipantListOpen && 'text-primary',
          )}
          onClick={() => setIsParticipantListOpen((prev) => !prev)}
        >
          <Icon name="UserProfileMultiple" />
        </button>
      </div>
      <div className="flex-1 w-full relative flex justify-center items-center gap-x-6">
        <button
          type="button"
          className={cs(
            'flex justify-center items-center w-12 h-12 rounded-lg bg-negative text-white',
            !callLoadingFinished && 'opacity-60',
          )}
          onClick={onLeave}
          disabled={!callLoadingFinished}
        >
          <Icon name="HangUp" />
        </button>
        <OptionButton
          iconName="CameraControl"
          muted={!isCameraEnabled}
          onPress={() => toggleCamera()}
        />
        <OptionButton
          iconName="MicrophoneControl"
          muted={!isMicrophoneEnabled}
          onPress={() => toggleMicrophone()}
        />
        <OptionButton iconName="ScreenShare" onPress={handleScreenShare} />
      </div>
      <div className="flex-1 justify-end hidden sm:flex">
        <RecordingControls variant="dark" />
      </div>
    </div>
  );
};

export default Toolbar;

/* eslint-disable no-param-reassign */
import { useContext } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import Sentry from '@advisor/utils/Sentry';
import Logger from '@advisor/utils/Logger';
import { useMyId } from '@advisor/api/me';
import ifExists from '@advisor/utils/ifExists';
import { useEvent } from '@advisor/utils/hooks';
import { atomWithMutation } from '@advisor/api/apollo';
import {
  CreateOrJoinVideoChatDocument,
  VideoRoomServiceProvider,
} from '@advisor/api/generated/graphql';
import {
  RecordingConsentState,
  recordingConsentStateAtom,
} from '../RecordingManager';
import SoundNotifications from '../SoundNotifications';
import { StaticMembraneAPIContext } from './MembraneAPIProvider';
import { StaticDailyAPIContext } from './DailyAPIProvider';
import callStateAtom from './callStateAtom';
import { VideoCallStatus } from './types';

const createOrJoinVideoChatAtom = atomWithMutation(
  CreateOrJoinVideoChatDocument,
);

function useConnect() {
  const callState = useAtomValue(callStateAtom);
  const setCallState = useSetAtom(callStateAtom);
  const myId = useMyId();

  const dailyAPI = useContext(StaticDailyAPIContext);
  const membraneAPI = useContext(StaticMembraneAPIContext);

  const createOrJoinVideoChat = useSetAtom(createOrJoinVideoChatAtom);
  const setRecordingConsentState = useSetAtom(recordingConsentStateAtom);

  const onError = useEvent((cause: unknown) => {
    Sentry.captureException(cause);
    setCallState(
      ifExists((draft) => {
        draft.status = VideoCallStatus.Error;
      }),
    );
  });

  return useEvent(async (chatRoomId: string) => {
    if (!myId) {
      return;
    }

    if (callState !== null) {
      Logger.log(`Trying to join video room, but we already joined one.`);
      return;
    }

    setCallState({
      chatRoomId,
      status: VideoCallStatus.Joining,
    });

    // Resetting consent when joining video-call
    setRecordingConsentState(RecordingConsentState.NOT_GIVEN_YET);

    SoundNotifications.playJoinSound();

    const results = (
      await createOrJoinVideoChat({
        variables: {
          chatRoomId,
        },
      })
    ).data?.createOrJoinVideoChat;

    const token = results?.token ?? null;
    const url = results?.url ?? null;
    const serviceProvider =
      results?.provider ?? VideoRoomServiceProvider.Membrane;

    const metadata = { userId: myId, cameraAspectRatio: undefined } as const;

    setCallState(
      ifExists((draft) => {
        draft.serviceProvider = serviceProvider;
      }),
    );

    Logger.log(
      `Initiating connection via ${serviceProvider} to ${url} (with token=${token})`,
    );

    if (serviceProvider === VideoRoomServiceProvider.Daily) {
      if (!dailyAPI) {
        onError(new Error(`No DailyAPIProvider present`));
        return;
      }

      dailyAPI.join(url, token, metadata);
    } else if (serviceProvider === VideoRoomServiceProvider.Membrane) {
      if (!membraneAPI) {
        onError(new Error(`No MembraneAPIProvider present`));
        return;
      }

      membraneAPI.join(url, token, metadata);
    } else {
      onError(new Error(`Unrecognized Service Provider: ${serviceProvider}`));
    }
  });
}

export default useConnect;
